.vertical-timeline:before {
  width: 3px !important;
  height: 300px;
  background: black;
  border-left: 3px solid white;
  border-right: 3px solid white;
  margin-top: -2px;
  margin-left: -5px !important;
  background: transparent !important;
}

.vertical-timeline-element-content {
  box-shadow: none !important;
  padding: 2em 3em !important;
  border-radius: 0;
  background: #181818 !important;
  color: white;
}

.vertical-timeline-element-content-arrow {
  border-right-color: #181818 !important;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}
