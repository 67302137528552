.customModal {
  background: #181818;
  font-family: "Raleway", sans-serif;
  color: white;
  text-align: center;
  padding: 32px;
}

.customModal div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.customModal h2 {
  margin-bottom: 24px;
}

.customModal p {
  line-height: 160%;
  margin-bottom: 24px;
}

.customModal .react-responsive-modal-closeButton svg {
  fill: white;
}
